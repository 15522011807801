<template>
  <div class="wrapper">
    <v-row no-gutters style="height: 100%">
      <div
        class="col-lg-6 site-banner"
        style="
          background-image: url('https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/e7f911620268d42205f453d67ad6c80c.jpg');
          background-position: center center;
          background-size: contain;
          display: flex;
          justify-content: center;
        "
      ></div>
      <div class="col-lg-6 site-content">
        <v-row no-gutters>
          <div class="col-lg-8 mx-auto my-5">
            <div class="text-center mb-4">
              <h2>¿Olvido su contraseña?</h2>
              <p>Ingrese su correo electronico registrado en la plataforma</p>
            </div>
            <div class="card">
              <v-form action class="form" ref="form" v-model="valid">
                <v-text-field
                  prepend-inner-icon="mdi-email-lock"
                  :rules="[rules.required, rules.email]"
                  @input="checkEmail()"
                  v-model="email"
                  id="email"
                  required
                  type="email"
                  placeholder="Ingresa tu correo electrónico"
                ></v-text-field>
                <div class="text-center">
                  <button
                    @click.prevent="signup()"
                    class="btn btn-primary"
                    style="color: #fff; background-color: #009ada; border-color: #009ada"
                  >
                    Procesar<v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                  </button>
                </div>
              </v-form>
            </div>
          </div>
        </v-row>
      </div>
    </v-row>
  </div>
</template>
<script>
  var urlRecoveryCreate = process.env.VUE_APP_API_DIRECTORY + 'password/create'

  export default {
    name: 'Recovery',
    data: () => ({
      email: '',
      error: false,
      valid: true,
      rules: {
        required: (value) => !!value || 'Requerido',
        min: (v) => v.length >= 6 || 'Escriba por lo menos 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'El formato de correo electrónico inválido'
        },
      },
    }),
    methods: {
      checkEmail() {},
      signup() {
        axios
          .post(urlRecoveryCreate, {
            email: this.email,
          })
          .then((response) => {
            this.$router.push('/session/activate-password')
          })
          .catch((error) => {
            this.error = true
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    background: #cbd8e1;
    height: 100vh;
    // padding: 25px;
    max-width: 1440px;
    margin: 0 auto;
  }
  .site-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    box-shadow: 0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 15%);
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
  .form {
    width: 80%;
  }
</style>
